import React, { Component } from 'react'
import axios from 'axios'

import { StyledError } from '../Collapse/stepped/style'
import { SubmitButton } from '../StylesPages/loginStyle'
import { ButtonContainer } from '../RestaurantProfile/components/Reservation/style'

export default class Identification extends Component<{ email: any; closeModal: any }, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
    }
  }

  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  handleSubmit = (event: any) => {
    event.preventDefault()
    if (this.handleValidation()) {
      this.checkCustomer()
    }
  }

  handleValidation = () => {
    const { fields } = this.state
    let errors: any = {}
    let formIsValid = true
    if (!fields['identification']) {
      formIsValid = false
      errors['identification'] = 'El número de identificación es requerido.'
    }
    if (/[ñáéíóúa-z _]/i.test(fields['identification'])) {
      formIsValid = false
      errors['identification'] = 'El número de identificación solo debe contener números.'
    }
    this.setState({ errors })
    return formIsValid
  }

  checkCustomer = () => {
    const { identification } = this.state.fields
    axios
      .get(`${process.env.API_URL}reservations/check-customer`, {
        params: { identification },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.customerId) {
          this.sendEmail(identification)
          this.props.closeModal('link_sent')
          this.props.email(data.email)
          return
        }
        this.props.closeModal('user_not_found')
      })
  }

  sendEmail = (identification: string) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    axios
      .post(
        `${process.env.API_URL}payment/update-payment-method-mail`,
        { identification },
        { headers }
      )
      .then(() => {})
  }

  render() {
    const { errors, fields } = this.state

    return (
      <div>
        <div className="row centered">
          <div className="col-12 col-sm-10">
            <form onSubmit={this.handleSubmit.bind(this)}>
              <div className="lbl lbl-text">Para generar el link, ingresa tu número de cédula</div>
              <div>
                <div className="lbl lbl-input">Ingresa tu número de identificación</div>
                <input
                  name="identification"
                  value={fields['identification'] || ''}
                  className={`input-st ${errors['identification'] ? 'error' : ''}`}
                  type="text"
                  onChange={this.handleUpdate.bind(this, 'identification')}
                />
                {errors['identification'] && <StyledError>{errors['identification']}</StyledError>}
              </div>
              <ButtonContainer className="btn-container">
                <SubmitButton type="submit" value="Generar Link" />
              </ButtonContainer>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
