import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { RequestGetter } from '../../helpers/request'
import withLocation from '../AuthSection/withLocation'
import { ModalContainer } from '../../components/RestaurantProfile/components/Reservation/style'
import { ModalContainerProcess } from '../../components/RestaurantProfile/components/Reservation/Modals/style'

const payicon = require('../../assets/images/pages/icons/pay-icon.svg')
const payedicon = require('../../assets/images/pages/icons/payed-icon.svg')
const erroricon = require('../../assets/images/pages/icons/error-icon.svg')

import { StyledButton, BoxWrapper } from './style'
import Identification from './Identification'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingLeft: 0,
    paddingRight: 0,
    width: '750px',
  },
}

declare const P: any

Modal.setAppElement('#___gatsby')

class UpdatePaymentSection extends Component<{ context: any }, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      membershipId: '',
      req: '',
      pay: false,
      payed: false,
      error: false,
      invalidToken: false,
      showModal: false,
      userNotFound: false,
      linkSent: false,
      email: null,
    }
  }

  getPaymentMessage = (status: string) => {
    let message = 'Por favor inténtalo nuevamente.'
    switch (status) {
      case 'REJECTED':
        message = 'Tu pago no se ha sido realizado.'
        break
      case 'PENDING':
        message =
          'Tu pago está siendo procesado, pronto recibirás un correo electrónico de tu transacción.'
        break
      case 'APPROVED':
        message = ''
        this.collect()
        break
      default:
        message = ''
        break
    }
    return message
  }

  componentDidMount() {
    const { search } = this.props
    if (search && search.token) {
      this.login(search.token)
    }
    setTimeout(() => {
      try {
        P.on('response', (o: any) => {
          const status = o.status.status
          this.setState({
            textStatus: this.getPaymentMessage(status),
          })
        })
      } catch (error) {
        console.log(error)
      }
    }, 1000)
  }

  login = (token: string) => {
    this.props.context
      .handleSSOLogin({ token })
      .then(() => {
        this.setState({ pay: true })
      })
      .catch((error: any) => {
        console.log(error)
        if ('invalid_token' == error.response.data.error) {
          this.setState({ invalidToken: true, pay: false })
        }
      })
  }

  handlePayment = (status: string) => {
    if (status == 'APPROVED') {
      this.setState({ pay: false, payed: true, error: false })
    } else if (status == 'REJECTED') {
      this.setState({ pay: false, payed: false, error: true })
    }
  }

  notify = (message: string) => toast(message)

  collect = () => {
    const idn = this.props.context.user.id
    const req = this.state.req
    const isUpdate = true
    RequestGetter('POST', 'generateTransaction', {
      idn,
      req,
      isUpdate,
    })
      .then((data: any) => {
        let status = data.data.status.status
        this.handlePayment(status)
      })
      .catch((error: any) => {
        this.handleError(error)
      })
  }

  handleError = (error: any) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 422:
          const { data } = error.response
          const errors = Object.keys(data)
          let response: any = []
          for (const index in data) {
            for (let i = 0; i < data[index].length; i++) {
              response[`error${index.charAt(0).toUpperCase() + index.slice(1)}`] = data[index][i]
            }
          }
          this.setState(response)
          break
        default:
          const message = error.response.data.message
            ? error.response.data.message
            : 'Hubo un problema en la transacción'
          this.notify(message)
          break
      }
    }
  }

  handleClick = () => {
    const idn = this.props.context.user.id
    RequestGetter('POST', 'generateUpdate', {
      idn,
    })
      .then((data: any) => {
        P.init(data.data.processUrl, {
          opacity: 0.4,
        })
        this.setState({ req: data.data })
      })
      .catch((error: any) => {
        this.handleError(error)
      })
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  closeModal = (closingType: string) => {
    switch (closingType) {
      case 'normal':
        this.setState({ showModal: false })
        return
      case 'link_sent':
        this.setState({ showModal: false, linkSent: true, invalidToken: false })
        return
      case 'user_not_found':
        this.setState({
          showModal: false,
          userNotFound: true,
        })
        return
    }
  }

  retrieveEmail = (email: string) => {
    this.setState({ email })
  }

  render() {
    const { pay, payed, error, invalidToken, showModal, userNotFound, linkSent, email } = this.state
    return (
      <React.Fragment>
        <BoxWrapper>
          <div className={pay ? '' : 'hide'}>
            <img className="img-responsive" src={payicon} />
            <h3>Tu método de pago ha caducado</h3>
            <p>
              Actualiza tu método de pago para seguir contando con los beneficios de Grande Table.
            </p>
            <StyledButton className="d-flex" onClick={this.handleClick}>
              Actualizar
            </StyledButton>
          </div>
          <div className={payed ? '' : 'hide'}>
            <img className="img-responsive" src={payedicon} />
            <h3>Gracias</h3>
            <p>Tu método de pago ha sido actualizado exitosamente.</p>
          </div>
          <div className={error ? '' : 'hide'}>
            <img className="img-responsive" src={erroricon} />
            <h3>Ha ocurrido un error</h3>
            <p>No pudimos actualizar tu método de pago.</p>
            <StyledButton className="d-flex" onClick={this.handleClick}>
              volver a intentar
            </StyledButton>
          </div>
          <div className={invalidToken ? '' : 'hide'}>
            <img className="img-responsive" src={erroricon} />
            <h3>El link ha caducado</h3>
            <p>Por favor solicítalo nuevamente.</p>
            <StyledButton className="d-flex" onClick={this.openModal}>
              Generar Link
            </StyledButton>
          </div>
          <div className={userNotFound ? '' : 'hide'}>
            <img className="img-responsive" src={erroricon} />
            <h3>La identificación ingresada no pertenece a un usuario de GrandeTable</h3>
            <p>Por favor ingresa nuevamente.</p>
            <StyledButton className="d-flex" onClick={this.openModal}>
              Generar Link
            </StyledButton>
          </div>
          <div className={linkSent ? '' : 'hide'}>
            <img className="img-responsive" src={erroricon} />
            <h3>
              Se ha enviado nuevamente el link a tu cuenta de correo vinculada con GrandeTable
            </h3>
            <p>
              En unos momento debes revisar tu correo electrónico {email} para dar clic en el link
              que acabamos de enviar.
            </p>
          </div>
        </BoxWrapper>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          draggable={true}
          pauseOnHover
        />
        <Modal
          style={customStyles}
          isOpen={showModal}
          onRequestClose={() => this.closeModal('normal')}
        >
          <ModalContainer className="container">
            <ModalContainerProcess>
              <div className="row close-btn">
                <i
                  className="icon-grande-table-21 close"
                  onClick={() => this.closeModal('normal')}
                />
              </div>
              <Identification closeModal={this.closeModal} email={this.retrieveEmail} />
            </ModalContainerProcess>
          </ModalContainer>
        </Modal>
      </React.Fragment>
    )
  }
}

UpdatePaymentSection.propTypes = {
  search: PropTypes.object,
}

export default withLocation(UpdatePaymentSection)
