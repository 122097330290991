import media from '../../styles/media'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const StyledRow = styled.div`
  background: #242424;
`

export const StyledButton = styled.button`
  &.d-flex {
    border: 1px solid ${theme.secondaryColor};
    padding: 10px 20px;
    color: #ffffff;
    background: ${theme.secondaryColor};
    text-transform: uppercase;
    &:hover {
      background-color: #ffffff;
      color: ${theme.secondaryColor};
    }
  }
`

export const BoxWrapper = styled.div`
  display: flex;
  flex:auto;

  div {
    &.hide{
      display: none;
    }
    text-align:center;
    margin: auto;
    color: ${theme.secondaryColor};
    border: solid #000 0px;
    border-radius: 5px;
    padding:10%;

    h3 {
      padding: 5px 0 0 0;
      font-size: 1.6rem;
      font-family: 'DroidSerif';
      font-weight: normal;
    }

    p {
      width: 80%;
      color: #000000;
      font-size: 0.85rem;    
      margin: auto;
      padding: 10px 0 20px;
    }

    button {
      border: none;
      padding: 5px 30px;
      border-radius:17.5px;
      color: #ffffff;
      background: ${theme.secondaryColor};
      margin: auto;
      font-size: 1rem;    
    }
  }

  ${media.sm`
    display: inherit;
  `};
`
