import React, { Component } from 'react'
import Layout from '../components/Layout'
import UpdatePaymentSection from '../components/UpdatePayment'
import AuthenticationContext from '../context/auth-context'

class UpdatePayment extends Component<{ context: any }, any> {
  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <AuthenticationContext.Consumer>
        {(context: any) => {
          return (
            <Layout themeType="dark" currentPage={'benefits'}>
              <UpdatePaymentSection context={context} />
            </Layout>
          )
        }}
      </AuthenticationContext.Consumer>
    )
  }
}
export default UpdatePayment
